import React from "react";
import { DialogType } from "./type";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { LanguageCodeEnum } from "generated/graphql";
import DownloadIcon from "assets/icon_download";

export default function SettleEditRequestDialog({
  isOpen,
  onClose,
}: DialogType) {
  const { t, i18n } = useTranslation();

  const handleDownload = () => {
    window.open(
      `${process.env.STORAGE_ORIGIN}/acon3d-public/%EA%B3%84%EC%A2%8C%20%EB%B3%80%EA%B2%BD%20%EC%8B%A0%EC%B2%AD%EC%84%9C.docx`
    );
  };
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: "400px",
          width: "100%",
        },
      }}
    >
      <Stack>
        <Stack
          sx={{ pl: 3, pr: 3, pt: 5, textAlign: "center" }}
          maxWidth={"400px"}
          spacing={3}
        >
          <Typography variant="body1">
            {parse(t("user.dialog.storeEditRequest"))}
          </Typography>
        </Stack>

        {i18n.language === LanguageCodeEnum.Ko && (
          <Stack alignItems={"center"} pt={3}>
            <Button
              onClick={handleDownload}
              variant="text"
              sx={{ width: "fit-content", padding: 0, margin: 0 }}
            >
              <DownloadIcon sx={{ marginRight: "8px" }} />
              <u>정산 정보 수정 요청 양식{`.`}docx</u>
            </Button>
          </Stack>
        )}
        <Stack direction="row" spacing={1.5} p={3}>
          <Button
            onClick={onClose}
            variant={"contained"}
            size="large"
            sx={{ flex: 1 }}
          >
            {t("dialog.confirm")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
